import React from 'react'
import MenuLink from "./MenuLink";


const Footer = () => {
    return (
        <div className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-xs-12 col-sm-12">
                        <nav className="footer-menu">
                            <MenuLink link={"/kontakt.html"} name={"Контакты"}/>
                            <MenuLink link={"/politic.html"} name={"Политика конфиденциальности"}/>
                            <a href={"https://da-net.online"}>https://da-net.online</a>
                            <p>Сайт создан в развлекательных целях. Все в Ваших руках. </p>
                            <p><a href="https://da-net.online/avia.html" target="_blank" style={{display: "inline"}}>Гадание таро телеграмм бот</a></p>
                        </nav>
                    </div>
                    <div className="col-lg-2 col-xs-12 col-sm-12">
                        <a
                            href='https://play.google.com/store/apps/details?id=com.devcomp.danet&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                                float: 'left',
                                margin: '5px 0'
                            }}
                        ><img
                            alt='Доступно в Google Play'
                            src='image/google.jpg'
                            width='136'
                            height='39'
                            style={{
                                width: '100%'
                            }}
                        /></a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer